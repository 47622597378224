import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./routes/app-routes";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import { useAuth } from "./contexts/auth";

export default function Content() {
  const JDtech = "Powered by JD Tech";
  const [currRoutes, setCurrRoutes] = useState(routes);
  const { user } = useAuth();

  useEffect(() => {
    const newRoutes = routes.filter((r) => {
      return r.allowedUserTypes.some((role) => {
        return user.userRoles.includes(role);
      });
    });
    setCurrRoutes(newRoutes);
  }, [user]);

  // function checkRole(user){
  //   if (user.role == "Stylist"){
  //     return (stylistRoutes.map(({ path, component }) => (
  //       <Route
  //         exact
  //         key={path}
  //         path={path}
  //         component={component}
  //       />
  //     )));
  //   }
  //   if (user.role == "Admin"){
  //     return (AdminRoutes.map(({ path, component }) => (
  //       <Route
  //         exact
  //         key={path}
  //         path={path}
  //         component={component}
  //       />
  //     )));
  //   }
  //   if (user.role == "FrontDesk"){
  //     return (frontDeskRoutes.map(({ path, component }) => (
  //       <Route
  //         exact
  //         key={path}
  //         path={path}
  //         component={component}
  //       />
  //     )));
  //   }
  // }

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {currRoutes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={currRoutes[0].path} />
      </Switch>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {JDtech}
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </SideNavBarLayout>
  );
}
