import React from 'react';
import './Inventory.scss';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  NumericRule
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';

// const URL = `gateway/cy3s87499h`
const URL = 'gateway/dh6zwk9ifl'//added logic to update unit price

function adjustToLocalTime(data) {
  var formattedTime = data.value
  if (new Date(data.value).getYear() < 100) {
    return ``
  }
  formattedTime = new Date(data.value).toLocaleString(`en-us`, { year: `2-digit`, month: `numeric`, day: `numeric`, hour: `numeric`, minute: `2-digit` })

  return `${formattedTime}`
}

function numericFormat(data) {
  let formattedNum = data.value
  formattedNum = (Math.round(formattedNum * 100) / 100).toFixed(2);
  if (isNaN(formattedNum)) {
    return data.value;
  }
  return `${formattedNum}`;
}

function addDollarSign(data) {
  let formattedNum = data.value
  formattedNum = (Math.round(formattedNum * 100) / 100).toFixed(2);
  if (isNaN(formattedNum)) {
    return data.value;
  }
  if (formattedNum < 0) {
    formattedNum = 0;
  }
  return `$${formattedNum}`;
}

async function sendBatchRequest(url, changes) {
  const result = await axios.post(url, { changes });

  if (result.status !== 200) {
    const json = result.data;
    throw json.Message;
  }
}

async function processBatchRequest(url, changes, component) {
  await sendBatchRequest(url, changes);

  await component.refresh(true);

  component.cancelEditData();
}

export default function Inventory(props) {
  const { location_name } = props?.location?.state || {};

  //const api = 'gateway/l15igme5r0'; //Uses CurrentInventoryQuantityAPI
  // const api = `gateway/0ajbwl8yzg`; //Uses Development-SQL-CurrentInventoryAPI
  const api = "gateway/lz5hw2wipk"//clone of Development-SQL-CurrentInventoryAPI (added unitary price per product)

  const {user} = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const dataSource = new CustomStore ({
    load: function() {
      return axios.post(api, userCredentials).then((result) => {
        console.log(result.data)
        return result.data;
      });
    }
  });

  const onSaving = React.useCallback((e) => {
    e.cancel = true;

    if (e.changes.length) {
      e.promise = processBatchRequest(URL, e.changes, e.component);
    }
  }, [])

  //check user role to show unit price column
  const showProductPriceColumn = user.role.toLowerCase() === 'system admin' || user.role.toLowerCase() === 'salon admin';

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Current Inventory</h2>
      <div className={'content-block'}>
        <DataGrid
          dataSource={dataSource}
          className={'dx-card wide-card'}
          showBorders={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowColumnResizing={true}
          onSaving={onSaving}
        >
          <Paging defaultPageSize={25} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Editing mode="batch" allowUpdating={true} allowAdding={false} allowDeleting={false}></Editing>
          <Scrolling mode="standard" />

          <Column dataField={'location_name'} caption={'Location'} filterValue={location_name || ""} />
          <Column dataField={'product_name'} caption={'Item'} />
          <Column dataField={`manufacturer_name`} caption={'Manufacturer'} />
          <Column dataField={`brand_name`} caption={`Brand`} />
          <Column dataField={`category_name`} caption={`Category`} />

          <Column
            dataField={'inventory_current_quantity'}
            caption={'Item Count'}
            dataType="text"
            customizeText={numericFormat}
          >
            <NumericRule />
          </Column>
          <Column
            dataField={`inventory_items_received`}
            caption={`Items Received`}
            dataType="text"
          >
            <NumericRule />
          </Column>
          <Column
            dataField={'inventory_desired_quantity'}
            caption={`Max Count`}
            dataType="text"
            sortOrder={location_name ? `desc` : ""}
          >
            <NumericRule />
          </Column>

          <Column
            dataField={`inventory_quantity_needed`}
            caption={`Order Quantity`}
            dataType="text"
            sortOrder={!location_name ? `desc` : ""}
            customizeText={numericFormat}
          >
            <NumericRule />
          </Column>

          <Column
            dataField={'inventory_on_hand'}
            caption={`Value on Hand`}
            dataType="text"
            customizeText={addDollarSign}
          >
            <NumericRule />
          </Column>

          {showProductPriceColumn && (
            <Column
              dataField={'inventory_product_price'}
              caption={'Unit Price'}
              dataType="text"
              customizeText={addDollarSign}
            />
          )}
          <Column dataField={'inventory_last_changed_user_id'} hidingPriority={8} alignment={'left'} />
          <Column dataField={'inventory_last_changed_timestamp'} caption={'Last Ordered'} customizeText={adjustToLocalTime} />
          <Column dataField={'inventory_notes'} hidingPriority={6} caption={'Notes'} />
          <Column dataField={'product_upc_code'} hidingPriority={7} caption={'Barcode'} />

          <Export enabled={true} allowExportSelectedData={false} />
        </DataGrid>
      </div>
    </React.Fragment>
  );
}
