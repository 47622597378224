import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Selection } from "devextreme-react/data-grid";

import { useLocation } from "react-router-dom";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup, Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import { useAuth } from "../../contexts/auth";

function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

export default function AddServiceToStylist(props) {
  const [organizations, setOrganizations] = useState([]);
  const [organizationsName, setOrganizationsNames] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);
  const [serviceName, setServiceName] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  const [stylistInfo, setStylistInfo] = useState({
    userId: null,
    firstName: "",
    lastName: "",
    userEmail: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userIdParam = parseInt(queryParams.get("param"), 10);

  const location = useLocation();

  const history = useHistory();

  const addServiceToUserAPI =
    "gateway/3orm8sratd";
  const searchStylistsApi =
    "gateway/igf3twd3c3";
  const searchServiceAPi =
    "gateway/ccme69ih27"; //search services under an org id
  const getOrganizationApi =
    "gateway/wankbr6e73"; // Just get's the user's locations

  const { user } = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  useEffect(() => {
    const requestData = {
      location: user.location_id,
    };
    axios
      .post(searchStylistsApi, requestData)
      .then((response) => {
        if (response.data) {
          const stylists = response.data;

          if (Array.isArray(stylists)) {
            const matchedStylist = stylists.find(
              (stylist) => stylist.user_id === userIdParam
            );
            if (matchedStylist) {
              setStylistInfo({
                userId: matchedStylist.user_id,
                firstName: matchedStylist.user_first_name,
                lastName: matchedStylist.user_last_name,
                userEmail: matchedStylist.user_email,
              });
            } else {
              console.log("No stylist found with the provided user ID.");
            }
          } else {
            console.error("Parsed data is not an array:", stylists);
          }
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching stylists:", error);
      });
  }, [location.search]);

  //get organization
  useEffect(async () => {
    const res = await axios.post(getOrganizationApi, userCredentials);

    const arrayData = res.data;
    if (Array.isArray(arrayData)) {
      setOrganizations(arrayData);
    }
  }, []);

  //set user organization name
  useEffect(() => {
    const matchingOrg = organizations.find((org) => {
      const parts = org.split(" ");
      return parts[0] === userCredentials.Organization.toString();
    });

    let organizationName = "";
    if (matchingOrg) {
      const parts = matchingOrg.split(" ");
      organizationName = parts.slice(1).join(" ");
    }
    setOrganizationsNames(organizationName);
  }, [organizations, userCredentials.Organization]);

  JSON.stringify(userCredentials);

  const dataSource = new CustomStore({
    load: function () {
      return axios.post(searchServiceAPi, userCredentials).then((result) => {
        let data = result.data;
        if (Array.isArray(data)) {
          const dataWithOrganization = data.map((item) => ({
            ...item,
            organizationName: organizationsName,
          }));
          setDataLoaded(true);

          return dataWithOrganization;
        } else {
          console.error("Data is not an array:", data);
          return [];
        }
      });
    },
    key: ["service_id"],
  });

  const handleRowClick = (e) => {
    const formServiceName = e.data.service_name;
    const formServiceId = e.data.service_id;
    setServiceName(formServiceName);
    setServiceId(formServiceId);
    setIsConfirmationPopupVisible(true);
  };

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
  };

  const handleSelectionChanged = ({ selectedRowKeys }) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleSubmit = async () => {
    const clientId = stylistInfo.userId;

    const servicesPayload = selectedRowKeys.map((serviceId) => ({
      clientId: clientId,
      serviceId: serviceId,
    }));

    try {
      const response = await axios.post(addServiceToUserAPI, servicesPayload);

      console.log("API Response:", response.data);
      history.push({
        pathname: "/edit-stylist-service",
        search: `?param=${clientId}`,
      });
    } catch (error) {
      console.error(
        "API Call Failed:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      // 'before' positions the button before other toolbar items (to the left)
      // 'after' would place it after other items (to the right)
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: `Add services to: ${stylistInfo.firstName} ${stylistInfo.lastName}`,
        onClick: handleSubmit,
      },
    });
  };

  const orderDateFormat = "M/d/yy";

  return (
    <React.Fragment>
      <h2 className={"content-block"}>
        Add Services: {stylistInfo.firstName} {stylistInfo.lastName}
      </h2>

      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        // onRowClick={handleRowClick}
        // focusedRowEnabled={dataLoaded}
        // defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
        onSelectionChanged={handleSelectionChanged}
      >
        <Selection mode="multiple" />
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
        <Scrolling mode="standard" />
        <Column
          dataField="organizationName"
          caption="Organization Name"
          allowSearch={false}
        />
        <Column
          dataField="service_name"
          caption="Service Name"
          allowSearch={true}
        />
        <Column
          dataField="service_shortcut"
          caption="Service Shortcut"
          allowSearch={true}
        />
        <Column
          dataField="description"
          caption="Description"
          allowSearch={false}
        />
        <Column
          dataField="service_status"
          caption="Service Status"
          allowSearch={false}
        />
      </DataGrid>
      {/* option to have the submit button at the bottom */}
      {/* <Button
        text="Submit Selected Services"
        onClick={handleSubmit}
        // Add any styling or positioning you need for the button
      /> */}

      {/* </Suspense> */}
    </React.Fragment>
  );
}
