import { Box, Typography } from "@mui/material";

export const renderCell = (item, time) => {
  const formatTime = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  };
  return (
    <div
      className={`schedule-item`}
      backgroundColor={"#c2c2c2"}
      style={{
        padding: "0px",
        backgroundColor: "white",
        display: "flex",
        gap: "2px",
      }}
      data-start={formatTime(item.start)}
      data-end={formatTime(item.end)}
    >
      <div
        style={{
          width: "10px",
          minWidth: "10px",
          backgroundColor:
            item.services[0].service_color ||
            item.services[0].color ||
            item.color,
        }}
      ></div>
      <div>
        {time && (
          <Typography noWrap>
            {formatTime(item.start)} - {formatTime(item.end)}
          </Typography>
        )}
        <Typography
          sx={{
            fontWeight: "800",
            textTransform: "capitalize",
          }}
          fontSize={12}
          noWrap
        >
          {item.client?.client_name || ""}
        </Typography>
        <div>
          {item?.services?.map((service) => (
            <div key={`${service.service_id}-${service.event_id}`}>
              <Typography noWrap>{service.title}</Typography>
              {service.service_shortcut && (
                <Typography>{service.service_shortcut}</Typography>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
