import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./edit-client-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function EditClientForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [formRenderKey, setFormRenderKey] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientID = queryParams.get("clientId");

  const getClientById =
    "gateway/rrou71mikj";

  const editClientById =
    "gateway/rw2hgi1tag";


  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  // Custom validation for phone number (integer check)
  const validatePhoneNumber = useCallback(({ value }) => {
    return !isNaN(value) && parseInt(value) == value && 7 <= value.length <= 12;
  }, []);

  //fetch client by id and set formData
  useEffect(() => {
    console.log("Starting fetchClientByID")
    setLoading(true);
    const fetchClientByID = async () => {
      console.log("In fetchClientByID function")
      const payload = {
        clientID: clientID
      };
      try {
        console.log("Sending payload")
        const response = await axios.post(getClientById, payload);

        const clientData = response.data[0];
        console.log("Back with client data: ", clientData)
        if (clientData) {
          formData.current = { ...formData.current, ...clientData }; // Set the client data to the formData ref
          setFormRenderKey((prevKey) => prevKey + 1); // Increment the key to trigger re-render
        }
      } catch (error) {
        console.error("Failed to fetch client details", error);
      }
    };
    if (clientID) {
      fetchClientByID();
    }
    setLoading(false);
  }, [clientID]);


  const firstNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "First Name",
  };
  const lastNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Last Name",
  };
  const emailEditorOptions = {
    stylingMode: "filled",
    placeholder: "Email",
    mode: "email",
  };
  const phoneEditorOptions = {
    stylingMode: "filled",
    placeholder: "Phone number",
    mode: "phone",
  };


  const birthDayEditorOptions = {
    stylingMode: "filled",
    placeholder: "Birth Date",
    displayFormat: "MM/dd",
    useMaskBehavior: true,
  };
  

  function formatDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Add 1 because months are 0-indexed
    const day = date.getDate();

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    //MySql date format
    return `${year}/${formattedMonth}/${formattedDay}`;
  }

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      // Assign organization and role to formData.current before destructuring
      formData.current.organization = userCredentials.Organization;
      formData.current.role = userCredentials.Role;

      // Check if the user's role includes 6, 3, or 2
      if (
        !userCredentials.Role.includes(6) &&
        !userCredentials.Role.includes(3) &&
        !userCredentials.Role.includes(2) &&
        !userCredentials.Role.includes(1)
      ) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(editClientById, formData.current);
        console.log(response);
        if (response.status === 200) {
          notify("Client Updated", "success", 3000);

          setTimeout(() => {
            history.push("/edit-client");
          }, 2000);
        }
        setLoading(false);
      } catch (e) {
        notify("Client Update failed, please try again", "error", 5000);
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <SingleCard title="Edit Client">
      <form className={"create-account-form"} onSubmit={onSubmit}>
        <Form 
          key={formRenderKey}
          formData={formData.current} 
          disabled={loading}>
          <Item
            dataField={"client_first_name"}
            editorType={"dxTextBox"}
            editorOptions={firstNameEditorOptions}
          >
            <RequiredRule message="First Name is required" />
            <Label visible={false} />
          </Item>

          <Item
            dataField={"client_last_name"}
            editorType={"dxTextBox"}
            editorOptions={lastNameEditorOptions}
          >
            <RequiredRule message="Last Name is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"client_birthday"}
            editorType={"dxDateBox"}
            editorOptions={birthDayEditorOptions}
          >
            <RequiredRule message="Birth Date is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"client_email"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"client_mobile_phone_number"}
            editorType={"dxTextBox"}
            editorOptions={phoneEditorOptions}
          >
            <RequiredRule message="Phone is required" />
            <CustomRule
              message="Phone must be a number"
              validationCallback={validatePhoneNumber}
            />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Update Client Information"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </SingleCard>
  );
}
