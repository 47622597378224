import React from 'react';
import './Stylist-Spend.scss';
import {Chart, ConstantLine, Export, Label, Series, ValueAxis} from 'devextreme-react/chart'
import CustomStore from 'devextreme/data/custom_store';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { useState, useRef, useCallback } from 'react';
import { DropDownBox, Form, List, DateBox, Button } from 'devextreme-react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary
} from 'devextreme-react/data-grid';
import { Value } from 'devextreme-react/range-selector';


export default function StylistSpend() {
  const {user} = useAuth()
  const api = 'gateway/n1m3ydd6g8'


let salonList = ['Fairfax', 'Rosslyn', 'Lee Heights']
const [salonListDataSource, setSalonListDataSource] = useState(salonList)
const listRef = useRef(null)
const [selectedSalon, setSelectedSalon] = useState(salonList[0])

var now = new Date();
var oneWeekAgo = new Date();
oneWeekAgo.setDate(now.getDate()-7)

const dropDownBoxRef = useRef(null);
const startDateRef = useRef(null);
const endDateRef = useRef(null);
const AverageStylistRef = useRef(null);
const TotalStylistRef = useRef(null);
const AverageStylistGridRef = useRef(null);

const changeDropDownBoxValue = useCallback((arg) => {
  setSelectedSalon(arg.addedItems[0]);
  dropDownBoxRef.current.instance.close()
}, []);

const onSalonValueChanged = useCallback((e) => {
  setSelectedSalon(e.value);
}, []);

const onClick = useCallback(() => {
  AverageStylistRef.current.instance.refresh()
  TotalStylistRef.current.instance.refresh()
  AverageStylistGridRef.current.instance.refresh()
})


  const chartDataSource = new CustomStore({
    key: 'Stylist',
    load: function() {
      var startDate = startDateRef.current.instance.option("value")
      var endDate = endDateRef.current.instance.option("value")
      let apiArguments = {
        Barcode: user.barcode,
        Role: user.role,
        Salon: selectedSalon,
        StartDate: startDate,
        EndDate: endDate,
      };
      JSON.stringify(apiArguments)
      console.log(apiArguments)
      return axios.post(api, apiArguments).then((result) => {
        return result.data;
      });
    }
  })

  return(
    <React.Fragment>
      <h2 className={'content-block'}>Stylist Spending </h2>
      <p className='content-block'>Total amounts each stylist spent over a given period of time, along with averages.</p>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <div id='input-box'>
    <h2 className={'content-block'}>Salon: </h2>
      <DropDownBox
        dataSource={salonListDataSource}
        placeholder='Select a Salon...'
        onValueChanged={onSalonValueChanged}
        value={selectedSalon}
        ref={dropDownBoxRef}
      >
        <List
          selectionMode='single'
          ref={listRef}
          dataSource={salonListDataSource}
          onSelectionChanged={changeDropDownBoxValue}
        />
      </DropDownBox>
      <div id='date-selection-box'>
        <h2 className={'content-block'}>Start Date: </h2>
        <DateBox
          type='date'
          value={oneWeekAgo}
          label='Start Date'
          labelMode='floating'
          ref={startDateRef}
          // onValueChanged={onStartDateValueChanged}
        />
        <h2 className={'content-block'}>End Date: </h2>
        <DateBox
          type='date'
          value={now}
          label='End Date'
          labelMode='floating'
          max={now}
          ref={endDateRef}
          //onValueChanged={onEndDateValueChanged}
        />
      </div>
      <Button text="Run Report" onClick={onClick}></Button>
    </div>
    <br></br>
      <Chart id='chart' dataSource={chartDataSource} ref={TotalStylistRef} title={'Total Stylist Spending'}>
        <Export
          enabled={true}
          fileName={'Total Stylist Spending'}
        />
            <Series 
              valueField='total_spend'
              argumentField='Stylist'
              name='Total spent'
              type='bar'
              color='#03A9F4'
            >
              <Label visible={true}>
              </Label>
            </Series>
            <Series 
              valueField='number_of_transactions'
              argumentField='Stylist'
              name='Number of Transactions'
              type='bar'
              color='#843D00'
            >
              <Label visible={true}>
              </Label>
            </Series>

            <Series 
            axis={'average'}
              valueField='average_spend'
              argumentField='Stylist'
              name='Average per transaction'
              type='bar'
              color='#236023'
            >
              <Label visible={true}>
              </Label>
            </Series>
            <ValueAxis
              name={'Total Spent'}
              title='Total Cost per transaction'
            ></ValueAxis>
            <ValueAxis
              name={'average'}
              title='Average Spent per Transaction'
              position='right'
            > 
            </ValueAxis>

            <ConstantLine
              width={2}
              //value={}
            ></ConstantLine>
          </Chart>
          <br></br>
          {/* <Chart 
          id='chart' 
          dataSource={chartDataSource} 
          ref={AverageStylistRef} 
          title={'Average Stylist Spending'}
          >
            <Export
            enabled={true}
            fileName={'Average Stylist Spending'}
            />
            <Series 
              valueField='average_spend'
              argumentField='Stylist'
              name='Average per transaction'
              type='bar'
              color='#03A9F4'
            >
              <Label visible={true}>
              </Label>
            </Series>
            <ConstantLine
              width={2}
              //value={}
            ></ConstantLine>
          </Chart>
          <br></br> */}

          <DataGrid
            dataSource={chartDataSource}
            className={'dx-card wide-card'}
            showBorders={true}
            ref={AverageStylistGridRef}
            //focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true} 
            allowColumnResizing = {true}
          
          >
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible ={true}/>
            <Scrolling mode="standard"/>
            <Export enabled={true} allowExportSelectedData={false}/>
            <Column dataField={'Stylist'} fixed={true}/>
            <Column dataField={'average_spend'} 
            sortOrder={'desc'} 
            caption={'Average Spent per Transaction'}
            dataType={'currency'}
            >
              <format type="fixedPoint" precision={2} />
            </Column>
            <Column dataField={'total_spend'} 
            caption={'Total Spent'} 
            dataType={'currency'}
            >
              <format type="fixedPoint" precision={2} />
            </Column>
            <Column dataField={'number_of_transactions'} 
            caption={'Number of Transactions'}
            alignment={"left"}/>


          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  )
};
