import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { Grid, Box, Autocomplete, TextField } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import Donut from "../../components/charts/donuts";
import BarStyle from "../../components/charts/bar1";
import VBarStyle from "../../components/charts/bar2";
import BarTopStyle from "../../components/charts/bar3";
import BarLowStyle from "../../components/charts/bar4";
import Loading from "../../assets/images/loading.gif";
import axios from "axios";
import "./salon.style.scss";
import { toast } from "react-hot-toast";

export default function SalonDashboard() {
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations
  const { user } = useAuth();
  const history = useHistory();

  const [locations, setLocations] = useState([]);
  const [salon, setSalon] = useState(null);
  const [valueOnHand, setValueOnHand] = useState(0.000001);
  const [valueUsed, setValueUsed] = useState(0);
  const [valueSpending, setValueSpending] = useState([]);
  const [valueConsumed, setValueConsumed] = useState([]);
  const [valueTopItems, setValueTopItems] = useState([]);
  const [valueRunningLow, setValueRunningLow] = useState([]);
  const [valueCSRatio, setValueCSRatio] = useState(0);
  const [loading, setLoading] = useState(false);

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
  };

  useEffect(async () => {
    if (userCredentials.Role.includes(3)) {
      const res = await axios.post(getLocationsAPI, userCredentials);
      setLocations(res.data);
    } else {
      var users_salon = {
        location_id: user.location_id,
        location_name: user.location_name,
        organization_id: parseInt(user.org),
      };
      var locations_array = [];
      locations_array.push(users_salon);
      setLocations(locations_array);
    }
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      setSalon(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    const url = "gateway/ebsb8f78ee"; // SalonInventoryOnHand lambda
    const locationId = salon?.location_id; // default Fairfax
    const days = 7; // default 7 days

    if (!locationId) {
      setValueOnHand(0);
      setValueUsed(0);
      setValueSpending([]);
      setValueConsumed([]);
      setValueTopItems([]);
      setValueRunningLow([]);
      setValueCSRatio(0);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await axios.post(url, {
          location_id: locationId,
          days: days,
        });

        setValueOnHand(result.data?.value_on_hand ?? 0 / 1);
        setValueUsed(result.data?.value_used ?? 0 / 1);
        setValueSpending(result.data?.stylist_spending || []);
        setValueConsumed(result.data?.most_consumed_product || []);
        setValueTopItems(result.data?.top_inventory_items || []);
        setValueRunningLow(result.data?.product_running_low || []);
        setValueCSRatio(result.data?.cs_ratio || 0);
      } catch (error) {
        toast.error(error?.message || "Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [salon]);

  const formatLabel = (value) => {
    if (Number.isNaN(value)) {
      return "$0";
    }
    const formattedNumber = value.toLocaleString();
    return `$${formattedNumber}`;
  };

  return (
    <div className="salon-dashboard-wrapper loading">
      <h2 className={"content-block"}>Salon Dashboard</h2>
      <div className={"responsive-paddings"}>
        <Grid
          container
          sx={{ textAlign: "center" }}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} md={7} className="salon-chart-container">
                <Autocomplete
                  disabled={!user?.userRoles?.some((x) => x === 3)}
                  sx={{
                    minWidth: "200px",
                    marginLeft: "0.5rem",
                  }}
                  value={salon}
                  getOptionLabel={(option) => option.location_name || ""}
                  onChange={(event, newValue) => {
                    setSalon(newValue);
                  }}
                  size="small"
                  options={locations}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
                <Grid container>
                  <Grid item lg={7} md={7} xs={12}>
                    <div className="chart-title">INVENTORY INVESTMENT</div>
                    <Grid
                      container
                      className="right-shadow bg-lightgray border-radius-normal"
                    >
                      <Grid item lg={6} md={6} sm={6} xs={12} className="pl-5p">
                        <Donut
                          color="#0dcae7"
                          value={valueOnHand}
                          total={valueOnHand}
                          label={formatLabel(valueOnHand)}
                          title="On Hand"
                          onClick={() =>
                            history.push({
                              pathname: "/inventory",
                              state: {
                                location_name: user.location_name || "Fairfax",
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12} className="pr-5p">
                        <Donut
                          color="#6ee60b"
                          value={valueUsed}
                          total={valueOnHand}
                          label={formatLabel(valueUsed)}
                          title="Used"
                          onClick={() =>
                            history.push({
                              pathname: "/reports-sql",
                              state: {
                                location_name: user.location_name || "Fairfax",
                              },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={5} md={5} xs={12}>
                    <div className="chart-title">CS RATIO</div>
                    <div className="bg-lightgray border-radius-normal radius-left-reset right-shadow px-5p">
                      <Donut
                        color="#ed0f7e"
                        value={valueCSRatio / 1}
                        total={100}
                        label="Color to"
                        title="Services Ratio"
                        noValue={true}
                        onClick={() =>
                          history.push({
                            pathname: "/rolled-reports-sql",
                            state: {
                              location_name: user.location_name || "Fairfax",
                            },
                          })
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                className="stylist-chart-container"
                style={{ height: "100%" }}
              >
                <h5 className="text-center">TIME PERIOD: PREVIOUS WEEK</h5>
                <div className="chart-title">
                  STYLIST SPENDING VS TRANSACTIONS VS AVG
                </div>
                <div className="bg-lightgray border-radius-normal">
                  <BarStyle
                    value={valueSpending}
                    onClick={() =>
                      history.push({
                        pathname: "/stylist-spend-sql",
                        state: {
                          location_name: user.location_name || "Fairfax",
                        },
                      })
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} sx={{ marginTop: "20px" }}>
                <div className="chart-title">MOST CONSUMED PRODUCTS</div>
                <div className="right-shadow bg-lightgray border-radius-normal">
                  <VBarStyle
                    value={valueConsumed}
                    onClick={() =>
                      history.push({
                        pathname: "/reporting",
                        state: {
                          location_name: user.location_name || "Fairfax",
                        },
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} sx={{ marginTop: "20px" }}>
                <div className="chart-title">TOP INVENTORY ITEMS</div>
                <div className="right-shadow bg-lightgray border-radius-normal">
                  <BarTopStyle
                    value={valueTopItems}
                    onClick={() =>
                      history.push({
                        pathname: "/inventory",
                        state: {
                          location_name: user.location_name || "Fairfax",
                        },
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="low-chart-container"
                sx={{ marginTop: "20px" }}
              >
                <div className="chart-title">PRODUCTS RUNNING LOW</div>
                <div className="bg-lightgray border-radius-normal">
                  <BarLowStyle
                    value={valueRunningLow}
                    onClick={() =>
                      history.push({
                        pathname: "/reorder",
                        state: {
                          location_name: user.location_name || "Fairfax",
                        },
                      })
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
