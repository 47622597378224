import { CreateAccountForm } from "../components";
import { CreateClientForm } from "../components";
import { CreateSalonForm } from "../components";
import { ConfigureSalonForm } from "../components";
import { CreateCategoryForm } from "../components";
import { CreateServiceForm } from "../components";
import { EditServiceForm } from "../components";
import { EditClientForm } from "../components";
import { withNavigationWatcher } from "../contexts/navigation";
import {
  HomePage,
  TasksPage,
  ProfilePage,
  ManageUsersPage,
  ReportsPage,
  ReportingPage,
  ManufacturersPage,
  RecipesPage,
  PivotgridClassPage,
  RolledReportsPage,
  SalonReportsPage,
  StylistSpendPage,
  InventoryPage,
  ReportsSQLPage,
  ManufacturersSQLPage,
  RolledReportsSQLPage,
  SalonReportsSQLPage,
  StylistSpendSQLPage,
  Scheduler,
  Receiving,
  Order,
  SearchServices,
  EditAClientPage,
  SearchStylists,
  AddServiceToStylist,
  EditStylistService,
} from "../pages";
import SalonDashboard from "../pages/dashboard/salon";
import StylistDashboard from "../pages/dashboard/stylist";
import StylistDetailDashboard from "../pages/dashboard/stylistdetail";

const routes = [
  {
    path: "/salon-dashboard",
    component: SalonDashboard,
    allowedUserTypes: [3, 2, 4, 1],
  },
  {
    path: "/stylist-dashboard",
    component: StylistDashboard,
    allowedUserTypes: [3, 2, 4, 1],
  },
  {
    path: "/home",
    component: HomePage,
    allowedUserTypes: [6],
  },
  {
    path: "/manage-users",
    component: ManageUsersPage,
    allowedUserTypes: [3, 2, 4],
  },
  {
    path: "/scheduler",
    component: Scheduler,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/reports",
    component: ReportsPage,
    allowedUserTypes: [3],
  },
  {
    path: "/reporting",
    component: ReportingPage,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/reports-sql",
    component: ReportsSQLPage,
    allowedUserTypes: [3, 2, 1],
  },

  {
    path: "/manufacturers",
    component: ManufacturersPage,
    allowedUserTypes: [3],
  },
  {
    path: "/manufacturers-sql",
    component: ManufacturersSQLPage,
    allowedUserTypes: [3],
  },
  {
    path: "/recipes",
    component: RecipesPage,
    allowedUserTypes: [3],
  },

  {
    path: "/pivotgrid-class",
    component: PivotgridClassPage,
    allowedUserTypes: [3],
  },

  {
    path: "/rolled-reports",
    component: RolledReportsPage,
    allowedUserTypes: [3],
  },

  {
    path: "/create-account",
    component: CreateAccountForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/create-account?role=stylist",
    component: CreateAccountForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/configure-salon",
    component: ConfigureSalonForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/create-salon",
    component: CreateSalonForm,
    allowedUserTypes: [6],
  },
  {
    path: "/create-category",
    component: CreateCategoryForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/create-service",
    component: CreateServiceForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/edit-service",
    component: EditServiceForm,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/edit-client-form",
    component: EditClientForm,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/create-client",
    component: CreateClientForm,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/search-services",
    component: SearchServices,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/add-service-to-stylist",
    component: AddServiceToStylist,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/edit-stylist-service",
    component: EditStylistService,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/search-stylists",
    component: SearchStylists,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/edit-client",
    component: EditAClientPage,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/salon-reports",
    component: SalonReportsPage,
    allowedUserTypes: [3],
  },
  {
    path: "/stylist-spend",
    component: StylistSpendPage,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/rolled-reports-sql",
    component: RolledReportsSQLPage,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/salon-reports-sql",
    component: SalonReportsSQLPage,
    allowedUserTypes: [3, 2],
  },
  {
    path: "/stylist-spend-sql",
    component: StylistSpendSQLPage,
    allowedUserTypes: [3, 2, 1],
  },
  {
    path: "/inventory",
    component: InventoryPage,
    allowedUserTypes: [3, 2],
  },

  {
    path: "/stylist-dashboard-detail",
    component: StylistDetailDashboard,
    allowedUserTypes: [3, 2, 4, 1],
  },
  {
    path: "/inventory-receiving",
    component: Receiving,
    allowedUserTypes: [3, 2, 4],
  },
  {
    path: "/reorder",
    component: Order,
    allowedUserTypes: [3, 2, 4],
  },
  {
    path: "/tasks",
    component: TasksPage,
    allowedUserTypes: [3],
  },
  {
    path: "/profile",
    component: ProfilePage,
    allowedUserTypes: [3],
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
