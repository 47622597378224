import axios from 'axios';

var recipes = [];
var loaded = false;

export default function GetRecipes() {
  if (!loaded) {
    const api =  'gateway/tdm0crvfhi';  // RecipeScanAPI

    axios
      .post(api)
      .then(result => {
        let resultBody = result.data['body'];

        console.log("resultBody: ",resultBody);

        let parsedBody = JSON.parse(resultBody);

        console.log("recipes: ",parsedBody.Items);

        recipes = parsedBody.Items;
        loaded = true;        
      });

  }
  return recipes;
};