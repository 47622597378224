export const weekConfig = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 0,
  startHour: 9,
  endHour: 20,
  step: 15,
  navigation: true,
  disableGoToDay: false,
};

export const dayConfig = {
  startHour: 9,
  endHour: 20,
  step: 15,
  navigation: true,
};

export const resourceFields = {
  idField: "user_id",
  textField: "user_name",
  subTextField: "location_name",
  avatarField: "user_name",
  colorField: "color",
};

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 151) + 50;
  const green = Math.floor(Math.random() * 151) + 50;
  const blue = Math.floor(Math.random() * 151) + 50;
  const color = `rgba(${red}, ${green}, ${blue})`;
  return color;
};

export const groupedSchedules = (data) => {
  let nextStartTime;

  const groupedData = data.reduce((acc, schedule) => {
    // const key = `${schedule.start}-${schedule.end}-${schedule.user_id}-${schedule.client_id}-${schedule.location_id}`;

    // if (acc[key]) {
    //   // If the schedule already exists in the accumulator, add the service to its array
    //   acc[key].services.push({
    //     event_id: schedule.event_id,
    //     service_id: schedule.service_id,
    //     title: schedule.title,
    //     service_shortcut: schedule.service_shortcut,
    //   });
    // } else {
    // Otherwise, create a new schedule with an array containing the service
    acc[schedule.event_id] = {
      start: new Date(schedule.start),
      end: new Date(schedule.end),
      event_id: schedule.event_id,
      title: schedule.client_name,
      user_id: schedule.user_id,
      user_name: schedule.user_name,
      client: {
        client_id: schedule.client_id,
        client_name: schedule.client_name,
      },
      location: {
        location_id: schedule.location_id,
        location_name: schedule.location_name,
      },
      services: [
        {
          event_id: schedule.event_id,
          service_id: schedule.service_id,
          title: schedule.title,
          service_shortcut: schedule.service_shortcut,
          service_color: schedule.service_color,
        },
      ],
    };
    // }

    return acc;
  }, {});

  const result = Object.values(groupedData);

  return result;
};

export const SchedulerAPI = {
  //get: "gateway/68nkedd2v8", //Uses production/Live API
  // get: `gateway/fh3c0gu345`, //Uses Development API
  get: "gateway/ut1y52bpq2",
  create: "gateway/4l0utv3x1g",
  modify: "gateway/41xph4o6bc",
  delete: "gateway/hpknmvnxz9",
  move: "gateway/t28n2xslfk",
};
