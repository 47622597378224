import React from 'react';
import './Manufacturers.scss';
import  TreeList, {
  Column, ColumnChooser, HeaderFilter, SearchPanel, SpeedDialAction, Lookup, Paging,
}  from 'devextreme-react/tree-list';
import products from './data';
import GetProducts from './GetProducts';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';

const expandedKeys = [2];
const selectedKeys = [1];

const api = "gateway/kywewrh4ti";

const store = new CustomStore({
  load: function() {
    return axios.post(api).then((result) => {
      return JSON.parse(result.data['body']).Items;
    });
  },

  update: function(key, values){
    
  }
});

export default () => (
  
  <React.Fragment>
    <h2 className={'content-block'}>Products</h2>
    <TreeList
      //dataSource = {products.getProducts()}
      dataSource = {store}
      showBorders = {true}
      columnAutoWidth = {true}
      wordWrapEnabled = {true}
      defaultExpandedRowKeys={expandedKeys}
      defaultSelectedRowKeys={selectedKeys}
      rootValue={-1}
      keyExpr={'Product_ID'}
      parentIdExpr={'Parent_Product_ID'}
      id = 'products'
    >
      <SearchPanel visible = {true} width = {250}/>
      <HeaderFilter visible = {true}/>
      {/* <Selection mode='multiple'/> */}
      <ColumnChooser enabled={false}/>
      <Paging
        enabled={true}
        defaultPageSize={50}
      />


      <Column dataField={'Manufacturer'} fixed={true} />
      <Column dataField={'Brand'} fixed={true} />
      <Column dataField={'Category'}fixed={true} />
      <Column dataField={'Type'} caption={'Type'} />
      <Column dataField={'Color Code'} caption={'Color Code'} />
      <Column dataField={'Process'} caption={'Process'} />
      <Column dataField={'ContainerSize'} caption={'Container Size'} />      
      <Column dataField={'ContainerCost'} caption={'Container Cost'} />
      <Column dataField={'UPC'} caption={'UPC'} alignment={'right'} />
      
    </TreeList>
  </React.Fragment>
);
