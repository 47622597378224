import defaultUser from "../utils/default-user";
import { Amplify, Auth } from "aws-amplify";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import UserPool from "../UserPool";
import notify from "devextreme/ui/notify";
import { useContext, useEffect } from "react";
import { APP_ID, APP_SECRET, BASE_URL, Context } from "../utils/config";
import axios from "axios";
import generator from "generate-password";

var user;
var sessionUserAttributes;
var API = "gateway/6qfhttr365"

export async function signIn(email, password) {
  var userData = {
    Username: email,
    Pool: UserPool,
  };

  user = new CognitoUser(userData);

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  //Send request
  return new Promise((resolve) => {
    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        console.log("onSuccess: ", data);
        var userAuthorizationData
        userAuthorizationData = await getUserAuthorization(data.idToken.payload['sub'])

        userAuthorizationData.user_roles = userAuthorizationData.user_roles
          .replace(/\[|\]/g, "")
          .split(",");
        userAuthorizationData.user_roles =
          userAuthorizationData.user_roles.map(Number);
        console.log(userAuthorizationData);
        // console.log(data.getIdToken().payload["custom:Barcode"]);
        // console.log(data.getIdToken().payload["custom:Role"]);
        // console.log(data.getIdToken().payload[`custom:Organization`])
        resolve({
          isOk: true,
          data: {
            email: data.getIdToken().payload.email,
            avatarUrl: null,
            accessToken: data.getAccessToken(),
            idToken: data.getIdToken(),
            //barcode: data.getIdToken().payload["custom:Barcode"],
            role: data.getIdToken().payload["custom:Role"],
            //org: data.getIdToken().payload[`custom:Organization`],

            location_id: userAuthorizationData.user_location_id,
            location_name: userAuthorizationData.location_name,
            userName: userAuthorizationData.user_name,
            userMeevoID: userAuthorizationData.user_meevo_id,
            userID: userAuthorizationData.user_id,
            barcode: userAuthorizationData.user_barcode,
            userRoles: userAuthorizationData.user_roles,
            org: userAuthorizationData.org_id.toString(),
            // refreshToken: data.getRefreshToken().getJwtToken(),
          },
        });
      },
      onFailure: (err) => {
        console.log(err);
        if (err == "UserNotConfirmedException: User is not confirmed.") {
          resolve({
            unconfirmed: true,
            isOk: false,
            message:
              "User not yet confirmed. Please check your email or contact your admin.",
            error: err,
          });
        } else {
          resolve({
            isOk: false,
            message:
              "Either your username or password is incorrect. Please check and try again.",
            error: err,
          });
        }
      },
      //Commenting out this section because 1) it's not in use currently, and 2) it contains a potential vulnerability in the hardcoded password.
      // newPasswordRequired: function (userAttributes, requiredAttributes) {
      //   //User was signed up by an admin and must provide new
      //   //password and required attributes, if any, to complete
      //   //authentication.

      //   //This comment and line of code came from the example
      //   //I found but are not applicable
      //   //the api doesn't accept this field back
      //   delete userAttributes.email_verified;
      //   delete userAttributes.email;

      //   //store userAttributes on global variable
      //   sessionUserAttributes = userAttributes;
      //   console.log(userAttributes);
      //   var newPassword = "Password123!";
      //   user.completeNewPasswordChallenge(newPassword, userAttributes, this);

      //   resolve({
      //     // message: "Password must be reset",
      //     // updatePassword: true,
      //     isOk: true,
      //     data: {
      //       email: email,
      //       avatarUrl: null,
      //     },
      //   });
      // },
    });
  });
}

export async function getUser() {
  try {
    user = UserPool.getCurrentUser();
    if (user != null) {
      //console.log(user);
      user.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else if (!session.isValid()) {
          console.log("Invalid session.");
        } else {
          //console.log("IdToken: " + session.getIdToken());
        }
      });
    } else {
      console.log("User not found.");
    }

    return {
      isOk: true,
      data: {
        email: user.getIdToken().payload.email,
        avatarUrl: null,
        accessToken: user.getAccessToken(),
        idToken: user.getIdToken(),
      },
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(
  fName,
  lName,
  email,
  role,
  location,
  organization
) {
  var userPool = UserPool;
  var tempPassword = generator.generate({
    length: 15,
    numbers: true,
    symbols: true,
  });
  //var tempPassword = 'tempP@ssword123'

  var attributeList = [];
  var dataEmail = {
    Name: "email",
    Value: email,
  };
  var dataFirstName = {
    Name: "given_name",
    Value: fName,
  };
  var dataLastName = {
    Name: "family_name",
    Value: lName,
  };
  var dataRole = {
    Name: "custom:Role",
    Value: role,
  };
  var dataLocation = {
    Name: "custom:Location",
    Value: location,
  };
  var dataOrg = {
    Name: "custom:Organization",
    Value: organization,
  };
  var attributeEmail = new CognitoUserAttribute(dataEmail);
  var attributeFirstName = new CognitoUserAttribute(dataFirstName);
  var attributeLastName = new CognitoUserAttribute(dataLastName);
  var attributeRole = new CognitoUserAttribute(dataRole);
  var attributeLocation = new CognitoUserAttribute(dataLocation);
  var attributeOrg = new CognitoUserAttribute(dataOrg);
  attributeList.push(attributeEmail);
  attributeList.push(attributeFirstName);
  attributeList.push(attributeLastName);
  attributeList.push(attributeRole);
  attributeList.push(attributeLocation);
  attributeList.push(attributeOrg);

  return new Promise((resolve) => {
    userPool.signUp(
      email,
      tempPassword,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          console.log(err);
          resolve({
            isOk: false,
            message: err,
          });
        }
        // var cognitoNewUser = result.user;
        resolve({
          isOk: true,
          message: "Email sent to new user for verification.",
          // message: "New user created: " + cognitoNewUser.getUsername(),
        });
      }
    );
  });
}

export async function changePassword(email, oldPassword, newPassword) {
  return new Promise((resolve) => {
    // This didn't work, and there's little-to-no documentation on this function
    // user.completeNewPasswordChallenge(newPassword, sessionUserAttributes);
    // resolve({
    //   isOk: true,
    // });
    user.changePassword(oldPassword, newPassword, function (err, result) {
      if (err) {
        resolve({
          isOk: false,
          message: err,
        });
      }
      console.log("call result: " + result);
      resolve({
        isOk: true,
        message: "User created: " + result,
      });
    });
  });
  // try {
  //   // Send request
  //   console.log(email, recoveryCode);

  //   return {
  //     isOk: true
  //   };
  // }
  // catch {
  //   return {
  //     isOk: false,
  //     message: "Failed to change password"
  //   }
  // }
}

export async function resetPassword(email) {
  return new Promise((resolve) => {
    // Send request
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.forgotPassword({
      onSuccess: function (data) {
        console.log(data);
        resolve({
          isOk: true,
        });
      },
      onFailure: function (err) {
        console.log(err);
        resolve({
          isOk: false,
          message: err,
        });
      },
    });
  });
}

export async function confirmPassword(email, verificationCode, newPassword) {
  return new Promise((resolve) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: function (data) {
        console.log(data);
        resolve({
          isOk: true,
        });
      },
      onFailure: function (err) {
        console.log(err);
        resolve({
          isOk: false,
          message: err,
        });
      },
    });
  });
}

export async function getToken() {
  return new Promise((resolve) => {
    axios
      .post(`${BASE_URL}/oauth2/token`, {
        client_id: APP_ID,
        client_secret: APP_SECRET,
      })
      .then((res) => {
        resolve({
          isOk: true,
          data: res.data,
        });
      });
  });
}

export function useToken() {
  const [token, setToken] = useContext(Context);

  useEffect(async () => {
    if (!token) {
      try {
        const res = await getToken();
        if (res.isOk) {
          const newToken = `${res.data?.token_type} ${res.data?.access_token}`;
          setToken(newToken);
        }
      } catch (err) {
        console.log("Error fetching token", err);
      }
    }
  });
}

function getUserAuthorization(username) {
  let userData = {
    username: username,
  };
  JSON.stringify(userData);

  return new Promise((resolve) => {
    axios.post(API, userData).then((result) => {
      resolve(result.data.Items[0]);
    });
  });
}
