import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useAuth } from "../../contexts/auth";
import { Scheduler, useScheduler } from "@aldabil/react-scheduler";
import BarSpent from "../../components/charts/bar5";
import Donut from "../../components/charts/donuts";
import { renderCell } from "../Scheduler/CellItem";
import "./stylist.style.scss";
import { getFields } from "../Scheduler/Fields";
import { renderDetailedItem } from "../Scheduler/DetailedItem";
import { resourceFields } from "../Scheduler/config";
import Stick from "../../components/charts/stick";
// import { cups } from "./cups.js";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import stringToColor from "string-to-color";

const getToday = () => {
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const today = new Date();
  const dayOfWeek = days[today.getDay()];
  const month = months[today.getMonth()];
  const day = today.getDate();
  const year = today.getFullYear();

  const formattedDate = `TODAY ${dayOfWeek} ${month} ${day}, ${year}`;

  return formattedDate;
};

export default function StylistDashboard() {
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { events, resources, setEvents, setResources } = useScheduler();
  const [totalSpent, setTotalSpent] = useState(0);
  const [averageSpent, setAverageSpent] = useState(0);
  const [valueCSRatio, setValueCSRatio] = useState(0);
  const [cups, setCups] = useState([]);

  const colors = ["#ed0f7e", "#0dcae7", "#6ee60b"];

  useEffect(() => {
    const url = "gateway/mrd29i1zr9" //Development-SQL-StylistDashboard
    //const url = "gateway/nix8mdbh84"; //Production StylistDashboard lambda
    const locationId = user.location_id || 1002; // default Demo location //This is Fairfax, not a demo location
    const userId = user.userID;

    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await axios.post(url, {
          location_id: locationId,
          user_id: userId,
        });

        const { schedules, totalSpent, averageSpent, cs_ratio, client_history } = result.data;
        setTotalSpent(totalSpent);
        setAverageSpent(averageSpent);
        setValueCSRatio(cs_ratio || 0);
        setCups(client_history || []);

        if (schedules.length > 0) {
          const resource = {
            user_id: schedules[0].user_id,
            user_name: schedules[0].user_name,
            location_id: schedules[0].location_id,
            location_name: schedules[0].location_name,
            color: stringToColor(schedules[0].user_name),
          }

          const events = schedules.map((schedule) => {
            const { start, end } = schedule;

            return {
              start: new Date(start),
              end: new Date(end),
              event_id: schedule.event_id,
              title: schedule.client_name,
              user_id: schedule.user_id,
              user_name: schedule.user_name,
              client: {
                client_id: schedule.client_id,
                client_name: schedule.client_name,
              },
              location: {
                location_id: schedule.location_id,
                location_name: schedule.location_name,
              },
              services: [{
                event_id: schedule.event_id,
                service_id: schedule.service_id,
                title: schedule.service_name,
                service_shortcut: schedule.service_shortcut,
              }],
            }
          });

          setResources([resource]);
          setEvents(events);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error?.message || "Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="stylist-dashboard-wrapper">
      <h2 className={"content-block"}>Stylist Dashboard</h2>
      <div className={"responsive-paddings"}>
        <Grid container>
          <Grid item xs={12} className="user-info">
            <h5>STYLIST: <span>{user.userName.toUpperCase()}</span></h5>
            <h5>TIME PERIOD: {getToday()}</h5>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={5} md={6} xs={12}>
            <div className="chart-title">YOUR SCHEDULE TODAY</div>
            <div className="scheduler-sider" onClick={() => history.push("/scheduler")}>
              {/* {events.length > 0 && ( */}
              <Scheduler
                timeZone="Africa/Abidjan"
                events={events}
                height={600}
                resources={resources}
                resourceFields={resourceFields}
                viewerExtraComponent={renderDetailedItem}
                fields={getFields({
                  stylists: resources
                })}
                week={null}
                month={null}
                deletable={false}
                editable={false}
                draggable={false}
                disabled={true}
                day={{
                  startHour: 9,
                  endHour: 20,
                  step: 60
                }}
                view="day"
                eventRenderer={(item) => renderCell(item, true)}
                onConfirm={null}
                onDelete={null}
                onEventClick={null}
                onEventDrop={null}
              />
              {/* )} */}
            </div>
          </Grid>
          <Grid item lg={7} md={6} xs={12} sx={{ paddingLeft: "3px" }}>
            <Grid container>
              <Grid item lg={8} md={12} xs={12}>
                <div className="chart-title">TOTAL SPENT VS AVERAGE $$ VS TRANSACTIONS</div>
                <div className="bg-lightgray right-shadow border-radius-normal">
                  <BarSpent onClick={() => history.push("/stylist-spend-sql")} totalSpent={totalSpent} averageSpent={averageSpent} />
                </div>
              </Grid>
              <Grid item lg={4} md={12} xs={12} xsOffset={3}>
                <div className="chart-title">CS RATIO</div>
                <div className="bg-lightgray border-radius-normal">
                  <Donut
                    color="#ed0f7e"
                    value={valueCSRatio / 1}
                    total={100}
                    label="Color to"
                    title="Services Ratio"
                    noValue={true}
                    onClick={() => history.push("/stylist-spend-sql")}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="summary-container">
              <Grid container>
                {
                  cups.map((cup, index) => {
                    return (
                      <Grid item lg={4} md={6} xs={12} key={index}
                        onClick={() => history.push({
                          pathname: "/stylist-dashboard-detail",
                          state: { cups: cup, index: index }
                        })}>
                        <Stick color={colors[index]} data={cup[0]} />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
