import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./edit-service-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function EditServiceForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [service, setService] = useState([]);
  const formData = useRef({ taxesIncluded: false });
  const [formRenderKey, setFormRenderKey] = useState(0);

  const getServiceById =
    "gateway/2m2zym0pu2";

  const editServiceById =
    "gateway/cz2m0mg7u4";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceId = queryParams.get("serviceId");

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  //fetch categories by organization
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/categories');
        const { categories } = response.data;
        setCategories(categories);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };

    fetchCategories();
  }, []);

  //used for the tax checkbox
  const toBoolean = (value) => {
    return value === "1" || value === true;
  };

  //status array
  const statusOptions = ["Active", "Inactive"];

  //category array
  const categoryOptions = useMemo(() => {
    return categories.map((category) => {
      return {
        text: category.category_name,
        value: {
          id: category.category_id,
          name: category.category_name,
        },
      };
    });
  }, [categories]); // Only recalculate if categories changes

  useEffect(() => {
    document.body.style.cursor = loading ? "progress" : "default";

    return () => {
      document.body.style.cursor = "default";
    };
  }, [loading]);

  //fetch service by id and set formData
  useEffect(() => {
    setLoading(true);
    const fetchServiceById = async () => {
      const payload = {
        serviceId: serviceId,
        role: userCredentials.Role,
        organization: userCredentials.Organization,
      };
      try {
        const response = await axios.post(getServiceById, payload);

        const serviceData = response.data[0];

        if (serviceData) {
          serviceData.taxes_included = toBoolean(serviceData.taxes_included); // Convert to boolean before setting it
          setService(serviceData); // Set the service data to the state
          formData.current = { ...formData.current, ...serviceData }; // Set the service data to the formData ref
          const selectedCategory = categoryOptions.find(
            (category) =>
              category.value.id.toString() === serviceData.category_id
          );
          //set the default category
          if (selectedCategory) {
            formData.current.category_id = selectedCategory.value;
          }
          setFormRenderKey((prevKey) => prevKey + 1); // Increment the key to trigger re-render
        }
      } catch (error) {
        console.error("Failed to fetch service details", error);
      }
    };
    if (serviceId) {
      fetchServiceById();
    }
    setLoading(false);
  }, [serviceId, categoryOptions]);

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Service name",
  };
  const shortcutNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Shortcut service name",
  };
  const descriptionEditorOptions = {
    stylingMode: "filled",
    placeholder: "Short description",
  };

  const statusEditorOptions = {
    items: statusOptions,
    searchEnabled: false,
    placeholder: "Choose a status",
  };
  const categoryEditorOptions = {
    items: categoryOptions,
    valueExpr: "value",
    displayExpr: "text",
    searchEnabled: true,
    placeholder: "Choose a category",
  };
  const priceEditorOptions = {
    placeholder: "Set a price",
    min: 0, // Ensuring the value can't be negative
    value: 0,
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      console.log("this is on submit", formData.current);
      // Assign organization and role to formData.current before destructuring
      formData.current.organization = userCredentials.Organization;
      formData.current.role = userCredentials.Role;

      // Check if the user's role includes 6, 3, or 2
      if (
        !userCredentials.Role.includes(6) &&
        !userCredentials.Role.includes(3) &&
        !userCredentials.Role.includes(2)
      ) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(editServiceById, formData.current);
        console.log(response);
        if (response.status === 200) {
          notify("Service Updated", "success", 3000);

          setTimeout(() => {
            history.push("/search-services");
          }, 3000);
        }
        setLoading(false);
      } catch (e) {
        notify("Service creation failed, please try again", "error", 5000);
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <div style={{ cursor: loading ? "progress" : "default" }}>
      <SingleCard title="Edit Service">
        <form className={"edit-service-form"} onSubmit={onSubmit}>
          <Form
            key={formRenderKey}
            formData={formData.current}
            disabled={loading}
          >
            <Item
              dataField={"service_name"}
              editorType={"dxTextBox"}
              editorOptions={nameEditorOptions}
            >
              <Label visible={false} />
              <RequiredRule message={"Please choose a service name"} />
            </Item>
            <Item
              dataField={"service_shortcut"}
              editorType={"dxTextBox"}
              editorOptions={shortcutNameEditorOptions}
            >
              <Label visible={false} />
              <RequiredRule message={"Please choose a shortcut service name"} />
            </Item>
            <Item
              dataField={"description"}
              editorType={"dxTextArea"}
              editorOptions={descriptionEditorOptions}
            >
              <Label visible={false} />
              <RequiredRule
                message={"Please add a short description of the service"}
              />
            </Item>
            <Item
              dataField={"service_status"}
              editorType={"dxSelectBox"}
              editorOptions={statusEditorOptions}
              label={{ visible: false }}
            >
              <RequiredRule message={"Please choose a status"} />
            </Item>
            <Item
              dataField={"category_id"}
              editorType={"dxSelectBox"}
              editorOptions={categoryEditorOptions}
              label={{ visible: false }}
            >
              <RequiredRule message={"Please choose a category"} />
            </Item>
            <Item itemType="group" colCount={2} caption="Set price levels">
              <Item itemType="group" colCount={1} caption="Level 1">
                <Item
                  dataField={"service_price_level_1"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    ...priceEditorOptions,
                    value: formData.current.service_price_level_1 || 0,
                  }}
                >
                  <Label visible={false} />
                  <RequiredRule
                  // message={
                  //   "Please add a price in you local currency (min amount zero)"
                  // }
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={1} caption="Level 2">
                <Item
                  dataField={"service_price_level_2"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    ...priceEditorOptions,
                    value: formData.current.service_price_level_2 || 0,
                  }}
                >
                  <Label visible={false} />
                  <RequiredRule
                  // message={
                  //   "Please add a price in you local currency (min amount zero)"
                  // }
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={1} caption="Level 3">
                <Item
                  dataField={"service_price_level_3"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    ...priceEditorOptions,
                    value: formData.current.service_price_level_3 || 0,
                  }}
                >
                  <Label visible={false} />
                  <RequiredRule
                  // message={
                  //   "Please add a price in you local currency (min amount zero)"
                  // }
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={1} caption="Level 4">
                <Item
                  dataField={"service_price_level_4"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    ...priceEditorOptions,
                    value: formData.current.service_price_level_4 || 0,
                  }}
                >
                  <Label visible={false} />
                  <RequiredRule
                  // message={
                  //   "Please add a price in you local currency (min amount zero)"
                  // }
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={1} caption="Level 5">
                <Item
                  dataField={"service_price_level_5"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    ...priceEditorOptions,
                    value: formData.current.service_price_level_5 || 0,
                  }}
                >
                  <Label visible={false} />
                  <RequiredRule
                  // message={
                  //   "Please add a price in you local currency (min amount zero)"
                  // }
                  />
                </Item>
              </Item>
            </Item>

            <Item
              dataField={"taxes_included"}
              editorType={"dxCheckBox"}
              editorOptions={{
                text: "Taxes Included",
              }}
            >
              <Label visible={false} />
            </Item>

            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Edit Service"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </form>
      </SingleCard>
    </div>
  );
}
