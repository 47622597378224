import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-service-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import axios from "axios";
import { CirclePicker, SliderPicker } from "react-color";

export default function CreateServiceForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const formData = useRef({ taxesIncluded: false });

  const createServiceApi =
    "gateway/6bz6n3gp0m";

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  //fetch categories by organization
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/categories');
        const { categories } = response.data;
        setCategories(categories);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };

    fetchCategories();
  }, []);

  //status array
  const statusOptions = ["Active", "Inactive"];

  //category array
  const categoryOptions = categories.map((category) => {
    return {
      text: category.category_name,
      value: {
        id: category.category_id,
        name: category.category_name,
      },
    };
  });

  const nameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Service name",
  };
  const shortcutNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Shortcut service name",
  };
  const descriptionEditorOptions = {
    stylingMode: "filled",
    placeholder: "Short description",
  };

  const statusEditorOptions = {
    items: statusOptions,
    searchEnabled: false,
    placeholder: "Choose a status",
  };
  const categoryEditorOptions = {
    items: categoryOptions,
    valueExpr: "value",
    displayExpr: "text",
    searchEnabled: true,
    placeholder: "Choose a category",
  };
  const priceEditorOptions = {
    placeholder: "Set a price",
    min: 0, // Ensuring the value can't be negative
    value: 0,
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      // Assign organization to formData.current before destructuring
      formData.current.organization = userCredentials.Organization;

      // Check if the user's role includes 6, 3, or 2
      if (
        !userCredentials.Role.includes(6) &&
        !userCredentials.Role.includes(3) &&
        !userCredentials.Role.includes(2)
      ) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return; // Exit the function if the user is not authorized
      }

      try {
        const response = await axios.post(createServiceApi, formData.current);
        console.log(response);
        if (response.status === 200) {
          notify("Service Created", "success", 3000);
          // Reload the page after a short delay
          setTimeout(() => {
            history.go(0);
          }, 3000);
        } // Wait for the notification to show before reloading
        setLoading(false);
      } catch (e) {
        notify("Service creation failed, please try again", "error", 5000);
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <SingleCard title="Add new Service">
      <form className={"create-service-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"name"}
            editorType={"dxTextBox"}
            editorOptions={nameEditorOptions}
          >
            <Label visible={false} />
            <RequiredRule message={"Please choose a service name"} />
          </Item>
          <Item
            dataField={"shortcutName"}
            editorType={"dxTextBox"}
            editorOptions={shortcutNameEditorOptions}
          >
            <Label visible={false} />
            <RequiredRule message={"Please choose a shortcut service name"} />
          </Item>
          <Item
            dataField={"description"}
            editorType={"dxTextArea"}
            editorOptions={descriptionEditorOptions}
          >
            <Label visible={false} />
            <RequiredRule
              message={"Please add a short description of the service"}
            />
          </Item>
          <Item
            dataField={"status"}
            editorType={"dxSelectBox"}
            editorOptions={statusEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule message={"Please choose a status"} />
          </Item>
          <Item
            dataField={"serviceCategory"}
            editorType={"dxSelectBox"}
            editorOptions={categoryEditorOptions}
            label={{ visible: false }}
          >
            <RequiredRule message={"Please choose a category"} />
          </Item>
          <Item itemType="group" colCount={2} caption="Set price levels">
            <Item itemType="group" colCount={1} caption="Level 1">
              <Item
                dataField={"price1"}
                editorType={"dxNumberBox"}
                editorOptions={priceEditorOptions}
              >
                <Label visible={false} />
                <RequiredRule
                // message={
                //   "Please add a price in your local currency (min amount zero)"
                // }
                />
              </Item>
            </Item>

            <Item itemType="group" colCount={1} caption="Level 2">
              <Item
                dataField={"price2"}
                editorType={"dxNumberBox"}
                editorOptions={priceEditorOptions}
              >
                <Label visible={false} />
                <RequiredRule
                // message={
                //   "Please add a price in your local currency (min amount zero)"
                // }
                />
              </Item>
            </Item>

            <Item itemType="group" colCount={1} caption="Level 3">
              <Item
                dataField={"price3"}
                editorType={"dxNumberBox"}
                editorOptions={priceEditorOptions}
              >
                <Label visible={false} />
                <RequiredRule
                // message={
                //   "Please add a price in your local currency (min amount zero)"
                // }
                />
              </Item>
            </Item>

            <Item itemType="group" colCount={1} caption="Level 4">
              <Item
                dataField={"price4"}
                editorType={"dxNumberBox"}
                editorOptions={priceEditorOptions}
              >
                <Label visible={false} />
                <RequiredRule
                // message={
                //   "Please add a price in your local currency (min amount zero)"
                // }
                />
              </Item>
            </Item>

            <Item itemType="group" colCount={1} caption="Level 5">
              <Item
                dataField={"price5"}
                editorType={"dxNumberBox"}
                editorOptions={priceEditorOptions}
              >
                <Label visible={false} />
                <RequiredRule
                // message={
                //   "Please add a price in your local currency (min amount zero)"
                // }
                />
              </Item>
            </Item>
          </Item>

          <Item
            dataField={"taxesIncluded"}
            editorType={"dxCheckBox"}
            editorOptions={{
              text: "Taxes Included",
            }}
          >
            <Label visible={false} />
          </Item>

          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Create a new Service"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </SingleCard>
  );
}
