import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { createAccount } from "../../api/auth";
import "./create-account-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function CreateAccountForm(props) {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [locations, setLocations] = useState([]);
  const [salon, setSalon] = useState(null);
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations
  const [locationNames, setLocationNames] = useState([]);
  const nameToIdMapRef = useRef({});
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  const searchParams = new URLSearchParams(location.search);
  const stylistRole = searchParams.get("role");

  useEffect(async () => {
    console.log(userCredentials);
    if (userCredentials.Role.includes(3)) {
      const res = await axios.post(getLocationsAPI, userCredentials);
      setLocations(res.data);
    } else {
      var users_salon = {
        location_id: user.location_id,
        location_name: user.location_name,
        organization_id: parseInt(user.org),
      };
      var locations_array = [];
      locations_array.push(users_salon);
      setLocations(locations_array);
    }
  }, []);
  useEffect(() => {
    setLocationNames(locations.map((location) => location.location_name));
    locations.forEach((location) => {
      nameToIdMapRef.current[location.location_name] = location.location_id;
    });
    console.log(nameToIdMapRef.current);
  }, [locations]);

  let roles;
  if (stylistRole) {
    roles = ["Stylist"];
  } else {
    roles = ["Stylist", "Salon Admin", "Front Desk"];
  }

  const firstNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "First Name",
  };
  const lastNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Last Name",
  };
  const emailEditorOptions = {
    stylingMode: "filled",
    placeholder: "Email",
    mode: "email",
  };
  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Password",
    mode: "password",
  };
  const confirmedPasswordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Confirm Password",
    mode: "password",
  };
  const roleEditorOptions = { items: roles, value: roles[0], searchEnabled: false };
  const locationEditorOptions = {
    items: locationNames,
    value: " ",
    searchEnabled: false,
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { firstName, lastName, email, location, password, role } =
        formData.current;
      setLoading(true);
      const selectedLocationId = String(nameToIdMapRef.current[location]);
      const organization = user.org;
      console.log(location, nameToIdMapRef.current);
      const result = await createAccount(
        firstName,
        lastName,
        email,
        role,
        selectedLocationId,
        organization
      );
      setLoading(false);

      if (result.isOk) {
        history.push("/manage-users");
        notify(result.message, "success", 3000);
      } else {
        notify(result.message, "error", 5000);
      }
    },
    [history]
  );

  const confirmPassword = useCallback(({ value }) => value === formData.current.password,
    []
  );

  return (
    <SingleCard title="Add New User">
      <form className={"create-account-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"firstName"}
            editorType={"dxTextBox"}
            editorOptions={firstNameEditorOptions}
          >
            <Label visible={false} />
          </Item>

          <Item
            dataField={"lastName"}
            editorType={"dxTextBox"}
            editorOptions={lastNameEditorOptions}
          >
            <Label visible={false} />
          </Item>
          <Item
            dataField={"email"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
            <Label visible={false} />
          </Item>
          {/* <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item> */}
          {/* <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item> */}
          <Item
            dataField={"location"}
            editorType={"dxSelectBox"}
            editorOptions={locationEditorOptions}
          >
            <RequiredRule message={"User must be assigned a location"} />
          </Item>
          <Item
            dataField={"role"}
            editorType={"dxSelectBox"}
            editorOptions={roleEditorOptions}
          >
            <RequiredRule message={"User must be assigned a role"} />
          </Item>
          {/* <Item>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>
          </div>
        </Item> */}
          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Create a new account"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
          {/* <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item> */}
        </Form>
      </form>
    </SingleCard>
  );
}
