import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LoadIndicator } from "devextreme-react/chart";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup, Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import { useAuth } from "../../contexts/auth";

function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

export default function SearchStylists(props) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  const [userId, setUserId] = useState([]);
  const [userFirstName, setUserFirstName] = useState([]);
  const [userLastName, setUserLastName] = useState([]);

  const history = useHistory();

  const searchStylistsApi =
    "gateway/igf3twd3c3";

  const { user } = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const location = userCredentials.Location;

  const dataSource = new CustomStore({
    load: function () {
      const requestData = {
        location: location,
      };
      return axios.post(searchStylistsApi, requestData).then((result) => {
        let data = result.data;
        if (Array.isArray(data)) {
          setDataLoaded(true);
          return data;
        } else {
          console.error("Data is not an array:", data);
          return [];
        }
      });
    },
    key: ["user_id"],
  });

  const handleRowClick = (e) => {
    const userId = e.data.user_id;
    const firstName = e.data.user_first_name;
    const lastName = e.data.user_last_name;

    console.log("User ID: ", userId);
    setUserId(userId);
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setIsConfirmationPopupVisible(true);
  };

  const handleConfirm = async () => {
    history.push({
      pathname: "/add-service-to-Stylist",
      search: `?param=${userId}`,
    });
    setIsConfirmationPopupVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
  };

  const handleAddNewService = () => {
    history.push("create-account?role=stylist");
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      // 'before' positions the button before other toolbar items (to the left)
      // 'after' would place it after other items (to the right)
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add New Stylist",
        onClick: handleAddNewService,
      },
    });
  };

  const orderDateFormat = "M/d/yy";

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Stylists</h2>
      <Popup
        visible={isConfirmationPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit stylist"
        showCloseButton={true}
        width={"30%"}
        height={"30%"}
      >
        <p>
          Are you sure you want to Add a service to{" "}
          <strong>
            {userFirstName} {userLastName}
          </strong>
          ?
        </p>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "10px" }}
          />
          <Button text="Confirm" type="default" onClick={handleConfirm} />
        </div>
      </Popup>

      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        focusedRowEnabled={dataLoaded}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        <Scrolling mode="standard" />
        <Column
          dataField="user_first_name"
          caption="First Name"
          allowSearch={true}
        />
        <Column
          dataField="user_last_name"
          caption="Last Name"
          allowSearch={true}
        />
        <Column dataField="user_email" caption="Email" allowSearch={true} />

        {/* <Column
    dataField="user_id"
    caption="User ID"
    visible={false}
  /> */}
      </DataGrid>
    </React.Fragment>
  );
}
