import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-client-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import axios from "axios";
import { dxDateBox } from "devextreme/ui/date_box";

export default function CreateClientForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [locations, setLocations] = useState([]);
  const [salon, setSalon] = useState(null);
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations
  const [locationNames, setLocationNames] = useState([]);
  const nameToIdMapRef = useRef({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationIdFromURL = queryParams.get("location_id");


  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  // Custom validation for phone number (integer check)
  const validatePhoneNumber = useCallback(({ value }) => {
    return !isNaN(value) && parseInt(value) == value && 7 <= value.length <= 12;
  }, []);


  const firstNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "First Name",
  };
  const lastNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Last Name",
  };
  const emailEditorOptions = {
    stylingMode: "filled",
    placeholder: "Email",
    mode: "email",
  };
  const phoneEditorOptions = {
    stylingMode: "filled",
    placeholder: "Phone number",
    mode: "phone",
  };


  const birthDayEditorOptions = {
    stylingMode: "filled",
    placeholder: "Birth Date",
    displayFormat: "MM/dd",
    useMaskBehavior: true,
  };
  

  function formatDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Add 1 because months are 0-indexed
    const day = date.getDate();

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    //MySql date format
    return `${year}/${formattedMonth}/${formattedDay}`;
  }

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstName, lastName, email, location, phone, birthDate } =
      formData.current;
    const organization = userCredentials.Organization;

    let date = "";
    if (birthDate instanceof Date && !isNaN(birthDate)) {
      date = formatDate(birthDate);
    }


    const response = await axios.post(
      "gateway/z1gki3w695",
      {
        firstName,
        lastName,
        email,
        location,
        phone,
        organization,
        date,
      }
    );
  
    const responseData = response.data;

    console.log(responseData.statusCode);
   // Parse the JSON string to an object
    const parsedResponse = responseData;

    // Extract the client_id
    const clientId = parsedResponse.client_id;
  
    if (responseData.statusCode === 200) {
      history.push(`/scheduler?client_id=${clientId}&location_id=${locationIdFromURL}`);
    } else {
      notify(responseData.message, "error", 5000);
    }
    setLoading(false);
  });

  return (
    <SingleCard title="Add New Client">
      <form className={"create-account-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"firstName"}
            editorType={"dxTextBox"}
            editorOptions={firstNameEditorOptions}
          >
            <RequiredRule message="First Name is required" />
            <Label visible={false} />
          </Item>

          <Item
            dataField={"lastName"}
            editorType={"dxTextBox"}
            editorOptions={lastNameEditorOptions}
          >
            <RequiredRule message="Last Name is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"birthDate"}
            editorType={"dxDateBox"}
            editorOptions={birthDayEditorOptions}
          >
            <RequiredRule message="Birth Date is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"email"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"phone"}
            editorType={"dxTextBox"}
            editorOptions={phoneEditorOptions}
          >
            <RequiredRule message="Phone is required" />
            <CustomRule
              message="Phone must be a number"
              validationCallback={validatePhoneNumber}
            />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Create a new client"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </SingleCard>
  );
}
