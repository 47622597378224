import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
  GroupItem,
} from "devextreme-react/form";
import {
  NumberBox as DxNumberBox,
  SelectBox as DxSelectBox,
} from "devextreme-react";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import { CheckBox as DxCheckBox } from "devextreme-react/check-box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { createAccount } from "../../api/auth";
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function EditStylistService(props) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [services, setServices] = useState([]);
  const [userToServiceData, setUserToserviceData] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = parseInt(queryParams.get("param"), 10);

  const getUserToServiceAPI =
    "gateway/h8s2akn3c7";
  const searchStylistsApi =
    "gateway/igf3twd3c3";
  const searchServiceAPi =
    "gateway/ccme69ih27"; //search services under an org id
  const updateUserToServiceAPI =
    "gateway/msyn4krb7c";

  const nameToIdMapRef = useRef({});

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resServices = await axios.post(getUserToServiceAPI, { userId });
        const servicesData = resServices.data;
        console.log(servicesData);

        const resAllServices = await axios.post(
          searchServiceAPi,
          userCredentials
        );
        const allServicesData = resAllServices.data;

        const initialFormData = {};
        servicesData.forEach((service) => {
          initialFormData[service.service_id] = {
            // priceLevel: service.price_level || "1", // Default to "1" if null
            priceLevel: service.price_level,
            servicing: service.service_step1_minutes || 0,
            gap: service.service_step2_minutes || 0,
            servicing2: service.service_step3_minutes || 0,
          };
        });

        // Update formData useRef with the initial form data
        formData.current = initialFormData;

        // Enriching servicesData with names from allServicesData
        const enrichedServices = servicesData.map((service) => {
          const foundService = allServicesData.find(
            (as) => as.service_id.toString() === service.service_id
          );
          return {
            ...service,
            service_name: foundService.service_name,
            service_price_level_1: foundService.service_price_level_1,
            service_price_level_2: foundService.service_price_level_2,
            service_price_level_3: foundService.service_price_level_3,
            service_price_level_4: foundService.service_price_level_4,
            service_price_level_5: foundService.service_price_level_5,
          };
        });

        setServices(enrichedServices);
        console.log("this are enriched Services", enrichedServices);

        console.log("this are services", services);
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };
    fetchData();
  }, []);

  // get stylist data based on param id
  useEffect(() => {
    const location = userCredentials.Location;
    const requestData = {
      location: location,
    };
    const fetchData = async () => {
      try {
        const res = await axios.post(searchStylistsApi, requestData);
        const data = res.data;
        const stylist = data.find((stylist) => stylist.user_id === userId);
        if (stylist) {
          setFirstName(stylist.user_first_name);
          setLastName(stylist.user_last_name);
        } else {
          console.log("Stylist not found");
        }
      } catch (error) {
        console.error("Error fetching stylists: ", error);
      }
    };
    fetchData();
  }, [userId]);

  const handleSubmit = async (serviceId) => {
    setLoading(true);
    console.log(`Submitting for serviceId ${serviceId}`, formData.current);

    const serviceData = formData.current[serviceId];

    const payload = {
      userId: userId,
      serviceId: serviceId,
      priceLevel: serviceData.priceLevel,
      servicing: serviceData.servicing || 0,
      gap: serviceData.gap || 0,
      servicing2: serviceData.servicing2 || 0,
    };

    console.log("Payload:", payload);

    try {
      const response = await axios.post(updateUserToServiceAPI, payload);

      console.log(response.data);
      if (response.data && response.data.statusCode === 200) {
        window.location.reload();
      } else {
        console.error(
          "Update not successful or unknown status:",
          response.data
        );
        setLoading(false);
      }
    } catch (e) {
      console.e("An error occurred:", e);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={"salon-conf dx-card"}
        style={{ width: "80%", margin: "auto" }}
      >
        <div className={"header"}>
          <div
            className={"title"}
            style={{
              fontSize: "24px",
              fontWeight: 500,
              marginBottom: "20px",
              marginTop: "10px",
              margin: "20px",
            }}
          >
            Edit Services for {firstName} {lastName}
          </div>
          <div
            className={"description"}
            style={{
              fontSize: "20px",
              fontWeight: 500,
              marginBottom: "20px",
              margin: "20px",
            }}
          >
            Configure {firstName} {lastName} service settings
          </div>
        </div>
        <React.Fragment>
          {services.map((service, index) => (
            <div
              key={service.service_id}
              className={"salon-conf dx-card"}
              style={{
                marginBottom: "30px",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
                width: "80%",
                margin: "auto",
                marginTop: index === 0 ? "20px" : "30px",
              }}
            >
              <div className={"header"}>
                <div
                  className={"title"}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    padding: "10px",
                  }}
                >
                  Service: {service.service_name}
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(
                    service.service_id,
                    formData.current[service.service_id]
                  );
                }}
              >
                <Form
                  ref={formRef}
                  formData={formData.current[service.service_id]}
                  disabled={loading}
                >
                  <Item
                    // dataField={`dropdown_${service.service_id}`}
                    dataField={"priceLevel"}
                    editorType={"dxSelectBox"}
                    editorOptions={{
                      items: [
                        {
                          value: "1",
                          text: `Price Level 1: ${service.service_price_level_1} $`,
                        },
                        {
                          value: "2",
                          text: `Price Level 2: ${service.service_price_level_2} $`,
                        },
                        {
                          value: "3",
                          text: `Price Level 3: ${service.service_price_level_3} $`,
                        },
                        {
                          value: "4",
                          text: `Price Level 4: ${service.service_price_level_4} $`,
                        },
                        {
                          value: "5",
                          text: `Price Level 5: ${service.service_price_level_5} $`,
                        },
                      ],
                      placeholder: "Select price level",
                      displayExpr: "text",
                      valueExpr: "value",
                      onValueChanged: (e) => {
                        formData.current[service.service_id] = {
                          ...formData.current[service.service_id],
                          priceLevel: e.value,
                        };
                      },
                    }}
                    label={{ visible: false }}
                  />
                  <GroupItem caption="Servicing (minutes)">
                    <Item
                      dataField="servicing"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            servicing: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>

                  <GroupItem caption="Gap (minutes)">
                    <Item
                      dataField="gap"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            gap: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>

                  <GroupItem caption="Servicing after gap (minutes)">
                    <Item
                      dataField="servicing2"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            servicing2: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>
                </Form>
                <button
                  type="submit"
                  disabled={loading}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#5c6bc0",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                    outline: "none",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    opacity: loading ? 0.7 : 1,
                    pointerEvents: loading ? "none" : "initial",
                  }}
                >
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    `Edit Service ${service.service_name}`
                  )}
                </button>
              </form>
            </div>
          ))}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}
