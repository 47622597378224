import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { createAccount } from "../../api/auth";
// import './configure-salon-form.scss';
// import { SalonConf } from '../../layouts';
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function ConfigureSalonForm(props) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [locations, setLocations] = useState([]);
  const [salon, setSalon] = useState(null);
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations
  const updateLocationApi =
    "gateway/v0gi2lnyki"; // for now on test db
  const [locationNames, setLocationNames] = useState([]);
  const nameToIdMapRef = useRef({});
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  const [isMondayOpen, setIsMondayOpen] = useState(false);
  const [isTuesdayOpen, setIsTuesdayOpen] = useState(false);
  const [isWednesdayOpen, setIsWednesdayOpen] = useState(false);
  const [isThursdayOpen, setIsThursdayOpen] = useState(false);
  const [isFridayOpen, setIsFridayOpen] = useState(false);
  const [isSaturdayOpen, setIsSaturdayOpen] = useState(false);
  const [isSundayOpen, setIsSundayOpen] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedSalon, setSelectedSalon] = useState("");
  const [fullLocation, setFullLocation] = useState("");
  const [locationKey, setLocationKey] = useState("");

  //testing for future auto-populate from db
  useEffect(async () => {
    const res = await axios.post(getLocationsAPI, userCredentials);
    console.log("this is locations", res.data);
    setFullLocation(res.data);
  }, []);

  useEffect(async () => {
    console.log(userCredentials);
    if (userCredentials.Role.includes(3)) {
      const res = await axios.post(getLocationsAPI, userCredentials);
      setLocations(res.data);
    } else {
      var users_salon = {
        location_id: user.location_id,
        location_name: user.location_name,
        organization_id: parseInt(user.org),
      };
      var locations_array = [];
      locations_array.push(users_salon);
      setLocations(locations_array);
    }
  }, []);

  useEffect(() => {
    // Create an array of objects with text (name) and value (id)
    setLocationNames(
      locations.map((location) => ({
        text: location.location_name,
        value: location.location_id,
      }))
    );

    locations.forEach((location) => {
      nameToIdMapRef.current[location.location_name] = location.location_id;
    });
  }, [locations]);

  //hour validation
  const isTimeAtLeast30MinsLater = (openTime, closeTime) => {
    const [openHour, openMinute] = openTime.split(":").map(Number);
    const [closeHour, closeMinute] = closeTime.split(":").map(Number);

    const openTimeInMinutes = openHour * 60 + openMinute;
    const closeTimeInMinutes = closeHour * 60 + closeMinute;

    return closeTimeInMinutes - openTimeInMinutes >= 30;
  };

  //this is the original
  // useEffect(() => {

  //   setLocationNames(locations.map((location) => location.location_name));
  //   locations.forEach((location) => {
  //     nameToIdMapRef.current[location.location_name] = location.location_id;
  //   });
  //   setLocationKey(nameToIdMapRef.current)
  // }, [locations]);

  // Custom validation for phone number (integer check)
  const validatePhoneNumber = useCallback(({ value }) => {
    return !isNaN(value) && parseInt(value) == value;
  }, []);

  const usTimeZones = [
    "Eastern Standard Time (EST)",
    "Central Standard Time (CST)",
    "Mountain Standard Time (MST)",
    "Pacific Standard Time (PST)",
    "Alaska Standard Time (AKST)",
    "Hawaii-Aleutian Standard Time (HAST)",
  ];

  const salonAddressEditorOptions = {
    stylingMode: "filled",
    placeholder: "Salon Address",
  };
  const phoneNumberEditorOptions = {
    stylingMode: "filled",
    placeholder: "Phone Number",
  };
  const timeZoneEditorOptions = {
    items: usTimeZones,
    value: selectedTimeZone,
    placeholder: "Select a time zone",
    onValueChanged: (e) => setSelectedTimeZone(e.value),
  };
  // const locationEditorOptions = {
  //   items: locationNames,
  //   value: selectedSalon,
  //   placeholder: "Select a Salon",
  //   searchEnabled: false,
  //   onValueChanged: (e) => setSelectedSalon(e.value),
  // };
  const locationEditorOptions = {
    items: locationNames,
    value: selectedSalon,
    displayExpr: "text", // tells the dropdown to display the text property
    valueExpr: "value", // tells the dropdown to use the value property as the actual value
    placeholder: "Select a Salon",
    searchEnabled: false,
    onValueChanged: (e) => setSelectedSalon(e.value),
  };

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? "00" : "30";
    return {
      id: index,
      time: `${hour.toString().padStart(2, "0")}:${minute}`,
    };
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      daysOfWeek.forEach((day) => {
        if (!formData.current[`is${day}Open`]) {
          formData.current[`${day.toLowerCase()}Open`] = "closed";
          formData.current[`${day.toLowerCase()}Close`] = "closed";
        }
      });
      const {
        salonAddress,
        phoneNumber,
        openingHours,
        timeZone,
        mondayOpen,
        mondayClose,
        tuesdayOpen,
        tuesdayClose,
        wednesdayOpen,
        wednesdayClose,
        thursdayOpen,
        thursdayClose,
        fridayOpen,
        fridayClose,
        saturdayOpen,
        saturdayClose,
        sundayOpen,
        sundayClose,
        location,
      } = formData.current;
      setLoading(true);
      console.log(formData.current);

      try {
        const res = await axios.post(updateLocationApi, formData.current);
        if (res.status === 200) {
          notify("Success", "success", 3000);
          // Reload the page after a short delay
          setTimeout(() => {
            history.go(0);
          }, 3000); // Wait for the notification to show before reloading
        } else {
          notify("Operation failed", "error", 5000);
        }
      } catch (error) {
        notify("Operation failed: " + error.message, "error", 5000);
      } finally {
        setLoading(false);
      }
    },
    [history, locations]
  );

  return (
    <React.Fragment>
      <div
        className={"salon-conf dx-card"}
        style={{ width: "80%", margin: "auto" }}
      >
        <div className={"header"}>
          <div
            className={"title"}
            style={{
              fontSize: "24px",
              fontWeight: 500,
              marginBottom: "20px",
              marginTop: "10px",
              margin: "20px",
            }}
          >
            Manage Salon
          </div>
          <div
            className={"description"}
            style={{
              fontSize: "20px",
              fontWeight: 500,
              marginBottom: "20px",
              margin: "20px",
            }}
          >
            Configure your salon settings
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <Form
            formData={formData.current}
            disabled={loading}
            style={{ margin: "0 auto", padding: "0 20px" }}
          >
            <Item
              dataField={"location"}
              editorType={"dxSelectBox"}
              editorOptions={locationEditorOptions}
              label={{ visible: false }}
            >
              <RequiredRule message={"Please Choose a Location"} />
            </Item>

            <Item
              dataField={"salonAddress"}
              editorType={"dxTextBox"}
              editorOptions={salonAddressEditorOptions}
            >
              <Label visible={false} />
              <RequiredRule message={"Please enter an Address"} />
            </Item>

            <Item
              dataField={"phoneNumber"}
              editorType={"dxTextBox"}
              editorOptions={phoneNumberEditorOptions}
            >
              <RequiredRule message="Phone is required" />
              <CustomRule
                message="Phone must be a number"
                validationCallback={validatePhoneNumber}
              />
              <Label visible={false} />
              <RequiredRule message={"Please enter a Phone Number"} />
            </Item>

            <Item
              dataField={"timeZone"}
              editorType={"dxSelectBox"}
              editorOptions={timeZoneEditorOptions}
              label={{ visible: false }}
            >
              <RequiredRule message={"Please choose a Time zone"} />
            </Item>

            <Item
              itemType="group"
              colCount={7}
              caption="Opening Days"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
                padding: "0 20px",
              }}
            >
              <Item
                dataField="isMondayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isMondayOpen,
                  text: "Monday",
                  onValueChanged: function (e) {
                    setIsMondayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isTuesdayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isTuesdayOpen,
                  text: "Tuesday",
                  onValueChanged: function (e) {
                    setIsTuesdayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isWednesdayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isWednesdayOpen,
                  text: "Wednesday",
                  onValueChanged: function (e) {
                    setIsWednesdayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isThursdayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isThursdayOpen,
                  text: "Thursday",
                  onValueChanged: function (e) {
                    setIsThursdayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isFridayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isFridayOpen,
                  text: "Friday",
                  onValueChanged: function (e) {
                    setIsFridayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isSaturdayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isSaturdayOpen,
                  text: "Saturday",
                  onValueChanged: function (e) {
                    setIsSaturdayOpen(e.value);
                  },
                }}
              />

              <Item
                dataField="isSundayOpen"
                label={{ visible: false }}
                editorType="dxCheckBox"
                editorOptions={{
                  value: isSundayOpen,
                  text: "Sunday",
                  onValueChanged: function (e) {
                    setIsSundayOpen(e.value);
                  },
                }}
              />
              <RequiredRule message={"Please choose a Time zone"} />
            </Item>

            {/* Group items for week's hours */}

            <Item itemType="group" colCount={1} caption="Opening Hours">
              <Item itemType="group" colCount={2} caption="Monday">
                <Item
                  dataField="mondayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isMondayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isMondayOpen && (
                    <RequiredRule message="Monday opening time is required" />
                  )}
                </Item>

                <Item
                  dataField="mondayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isMondayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isMondayOpen && (
                    <RequiredRule message="Monday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.mondayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={2} caption="Tuesday">
                <Item
                  dataField="tuesdayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isTuesdayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isTuesdayOpen && (
                    <RequiredRule message="Tuesday opening time is required" />
                  )}
                </Item>

                <Item
                  dataField="tuesdayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isTuesdayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isTuesdayOpen && (
                    <RequiredRule message="Tuesday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.tuesdayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={2} caption="Wednesday">
                <Item
                  dataField="wednesdayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isWednesdayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isWednesdayOpen && (
                    <RequiredRule message="Wednesday opening time is required" />
                  )}
                </Item>

                <Item
                  dataField="wednesdayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isWednesdayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isWednesdayOpen && (
                    <RequiredRule message="Wednesday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.wednesdayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>
              <Item itemType="group" colCount={2} caption="Thursday">
                <Item
                  dataField="thursdayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isThursdayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isThursdayOpen && (
                    <RequiredRule message="Thursday opening time is required" />
                  )}
                </Item>

                <Item
                  dataField="thursdayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isThursdayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isThursdayOpen && (
                    <RequiredRule message="Thursday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.thursdayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>
              <Item itemType="group" colCount={2} caption="Friday">
                <Item
                  dataField="fridayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isFridayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isFridayOpen && (
                    <RequiredRule message="Friday opening time is required" />
                  )}
                </Item>
                <Item
                  dataField="fridayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isFridayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isFridayOpen && (
                    <RequiredRule message="Friday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.fridayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={2} caption="Saturday">
                <Item
                  dataField="saturdayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isSaturdayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isSaturdayOpen && (
                    <RequiredRule message="Saturday opening time is required" />
                  )}
                </Item>
                <Item
                  dataField="saturdayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isSaturdayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isSaturdayOpen && (
                    <RequiredRule message="Saturday closing time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.saturdayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>

              <Item itemType="group" colCount={2} caption="Sunday">
                <Item
                  dataField="sundayOpen"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "8:00",
                    width: "120px",
                    disabled: !formData.current.isSundayOpen,
                  }}
                  label={{ text: "Open", location: "top" }}
                >
                  {formData.current.isSundayOpen && (
                    <RequiredRule message="Sunday opening time is required" />
                  )}
                </Item>
                <Item
                  dataField="sundayClose"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: timeOptions,
                    displayExpr: "time",
                    valueExpr: "time",
                    placeholder: "17:00",
                    width: "120px",
                    disabled: !formData.current.isSundayOpen,
                  }}
                  label={{ text: "Close", location: "top" }}
                >
                  {formData.current.isSundayOpen && (
                    <RequiredRule message="Sunday opening time is required" />
                  )}
                  <CustomRule
                    message="Closing time must be at least 30 minutes after opening time"
                    validationCallback={function (params) {
                      return isTimeAtLeast30MinsLater(
                        formData.current.sundayOpen,
                        params.value
                      );
                    }}
                  />
                </Item>
              </Item>
            </Item>

            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Edit Salon"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </form>
      </div>
    </React.Fragment>
  );
}
