import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import './manage-users.scss';


export default function ManageUsers() {
  const history = useHistory();
  const dataRef = useRef(null);

  const dataSource = new DataSource({
    store: new CustomStore({
      load: function () {
        return axios.get('/users').then((result) => {
          return result?.data?.users;
        });
      },
      key: 'user_id'
    })
  });

  const onCreateUser = useCallback(async (e) => {
    history.push('/create-account');
  }, []);

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Manage Users</h2>
      <Button text={"Add New User"} onClick={onCreateUser} />
      <DataGrid
        ref={dataRef}
        dataSource={dataSource}
        keyExpr="ID"
        className={'dx-card wide-card'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={false} />

        <Column
          dataField={'location_name'}
          caption={'Salon'}
          hidingPriority={4}
        />
        <Column
          dataField={'user_first_name'}
          caption={'First Name'}
          hidingPriority={9}
        />
        <Column
          dataField={'user_last_name'}
          caption={'Last Name'}
          hidingPriority={10}
        />
        <Column
          dataField={'user_status'}
          caption={'Status'}
          hidingPriority={10}
          customizeText={(data) => ({
            'PENDING': 'Pending',
            'ACTIVE': 'Active',
            'INACTIVE': 'Inactive',
            'DELETED': 'Deleted',
          }[data.value])}
        />
        {/* 
        <Column
          dataField={'role_name'}
          caption={'Role'}
          hidingPriority={6}
        /> */}

        <Column
          dataField={'user_barcode'}
          caption={'Barcode'}
          hidingPriority={5}
        />
        {/* 
        <Column
          dataField={'org_name'}
          caption={'Organization'}
          hidingPriority={5}
        /> */}
        <Column
          dataField={'user_phone'}
          caption={'Phone #'}
          hidingPriority={7}
        />
        <Column
          dataField={'user_email'}
          caption={'Email'}
        />
      </DataGrid>
    </React.Fragment>
  );
}
