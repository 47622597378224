import { createContext } from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const BASE_URL = "https://d18devmarketplace.meevodev.com";
export const PAGE_SIZE = 200;
export const BASE_ENDPOINT = "https://d18devpub.meevodev.com/publicapi/v1";
export const APP_ID = "50add9b8-c523-40c5-b74c-961b6da3e45f";
export const APP_SECRET = "e6298efc-cb30-45ad-b7d6-3123e0b30b97";

export const COGNITO_POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

export const Context = createContext(null);